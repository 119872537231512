import {
  onLCP,
  onINP,
  onCLS,
  LCPMetric,
  INPMetric,
  CLSMetric,
} from 'web-vitals';

const getWindow = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.top?.document;
    return window.top || window.self;
  } catch (e) {
    return window.self;
  }
};

const setupReporting = () => {
  const pageWindow = getWindow();
  const { title: productPageTitle } = pageWindow.document;
  const { title: appPageTitle } = document;
  return (metric: LCPMetric | INPMetric | CLSMetric) => {
    const productId = pageWindow?.emui?.appId ?? '';
    const version = pageWindow?.emui?.version ?? '';
    const appId = window?.emui?.appId ?? '';
    const { pathname: productPath, href: productUrl } = pageWindow.location;
    const { pathname: appPath, href: appUrl } = window.location;
    window.gtmDataLayer = window.gtmDataLayer || [];
    window.gtmDataLayer.push({
      event: 'webvitals',
      ...metric,
      productId,
      productPath,
      productUrl,
      productPageTitle,
      version,
      appId,
      appPath,
      appUrl,
      appPageTitle,
    });
  };
};

const onReport = setupReporting();

onLCP(onReport);
onINP(onReport);
onCLS(onReport);
