import { Logger } from '@elliemae/pui-diagnostics';
import { BRUMConfig } from './brumConfig.js';

export interface AJAX {
  url: (url: string) => void;
  markSendTime: (time: number) => void;
  markFirstByteTime: (time: number) => void;
  markRespAvailTime: (time: number) => void;
  markRespProcTime: (time: number) => void;
}

interface AJAXConstructor {
  new (): AJAX;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VPageView {}

interface VPageViewConstructor {
  new (): VPageView;
}

export interface ADRUMError {
  line: (line: number) => void;
  msg: (msg: string) => void;
}

interface ADRUMErrorConstructor {
  new (): ADRUMError;
}

interface ADRUMType {
  report: (event: any) => void;
  listenForErrors: () => void;
  markVirtualPageEnd: () => void;
  markVirtualPageBegin: (myCustomVPName: string, autoEnd: boolean) => void;
  command: (command: string, value: string) => void;
  events: {
    Ajax: AJAXConstructor;
    VPageView: VPageViewConstructor;
    Error: ADRUMErrorConstructor;
  };
}

interface BAEvent {
  event: string;
  [key: string]: any;
}

interface EMUI {
  brumConfig: BRUMConfig;
  logger: Logger;
  appId: string;
  version: string;
}

declare global {
  interface Window {
    emui: EMUI;
    emuiDiagnostics: {
      logUnhandledErrors: (logger: Logger) => void;
    };
    gtmDataLayer: Array<BAEvent>;
    'adrum-config': BRUMConfig;
    'adrum-app-key': string;
    'adrum-start-time': number;
    'adrum-disable': boolean;
    ADRUM: ADRUMType;
  }
}

export const getBrowserWindow = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.parent.document;
    return window.parent;
  } catch (err) {
    return window;
  }
};
